<template>
  <div>
    <b-link :to="{ name: 'addServerApp' }">
      Ajouter une application serveur
    </b-link>
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
              v-model="searchedName"
              placeholder="Rechercher par nom"
              @keyup.enter="searchServerAppsByName"
          />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="searchServerAppsByName">
              <font-awesome-icon icon="search"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col v-if="serverApps.length > 0">
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadServerApps"
        />
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col v-if="loading">
        <ServerAppListSkeleton/>
      </b-col>
      <b-col v-else>
        <ServerAppList v-if="serverApps.length > 0" :serverApps="serverApps"/>
        <span v-else>
          Aucun application serveur ne correspond à votre recherche
        </span>
      </b-col>
    </b-row>
    <b-row v-if="serverApps.length > 0">
      <b-col>
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadServerApps"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ServerAppList from "@/components/serverApps/ServerAppList";
import ServerAppListSkeleton from "@/components/serverApps/ServerAppListSkeleton.js";

export default {
  components: {
    ServerAppList,
    ServerAppListSkeleton,
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      searchedName: null,
    };
  },
  computed: {
    ...mapState({
      serverApps: (state) => state.serverApps.all,
      total: (state) => state.serverApps.total,
      pageSize: (state) => state.serverApps.pageSize,
    }),
  },
  async created() {
    await this.loadServerApps();
  },
  methods: {
    async loadServerApps(page = 1) {
      this.loading = true;
      try {
        await this.$store.dispatch("serverApps/fetchServerApps", {
          page,
          name: this.searchedName ?? null,
        });
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la récupération des applications serveurs",
          text: e.message,
        });
        return;
      }
      this.loading = false;
    },
    searchServerAppsByName() {
      this.currentPage = 1;
      this.loadServerApps(this.currentPage);
    },
  },
};
</script>

