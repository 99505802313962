<template>
  <b-table
      hover
      striped
      borderless
      head-variant="light"
      :items="serverApps"
      :fields="fields"
  >
    <template #cell(fullName)="app">{{ app.item.name }}</template>
    <template #cell(edit)="app">
      <div class="row justify-content-end mr-0">
        <EditDeleteButtonGroup
            @edit="editServerApp(app.item)"
            @delete="deleteServerApp(app.item)"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import EditDeleteButtonGroup from "@/components/buttons/EditDeleteButtonGroup";

export default {
  props: {
    serverApps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {key: "name", label: "Nom"},
        {key: "edit", label: ""},
      ],
    };
  },
  components: {
    EditDeleteButtonGroup,
  },
  methods: {
    editServerApp(serverApp) {
      this.$router.push({
        name: "serverApp",
        params: {uid: serverApp.uid},
      });
    },
    async deleteServerApp(serverApp) {
      try {
        await this.$store.dispatch("serverApps/deleteServerApp", serverApp.uid);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la suppression de l'application serveur",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Application serveur supprimée",
      });
    },
  },
};
</script>

